import React, { useEffect, useState } from 'react';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react';
import ReactLoading from 'react-loading';
function Service() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
    }, []);
    function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
        console.log('LiveChatWidget.onNewEvent', event);
    }

    return (
        <>
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                    <ReactLoading type="bubbles" color="#ff8a00" height={10} width={70} />
                </div>
            )}
            <LiveChatWidget license="" visibility="maximized" />;
        </>
    );
}

export default Service;
