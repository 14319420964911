import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
function We() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t } = useTranslation();
    return (
        <div className="withdraw px-[15px] py-[20px] min-h-[920px] bg-[#f2f2f2]">
            <div className="bg-[#fff] text-2xl rounded-2xl py-4 px-4 leading-10">
                <span>CÔNG TY DỊCH THUẬT idichthuat</span>
                <br />
                <br />
                <span>
                    Công ty dịch thuật idichthuat Việt Nam - nhà bán lẻ hàng đầu của Nhật Bản và châu Á - đã tạo được sự
                    hiện diện mạnh mẽ tại Việt Nam từ năm 2011. Tại idichthuat, tập trung vào khách hàng là triết lý
                    kinh doanh trung tâm của chúng tôi, để cam kết điều này, chúng tôi tập trung vào việc nâng cao sự
                    tiện lợi cho khách hàng và liên tục cung cấp các dịch vụ khách hàng tốt nhất.
                </span>
                <br />
                <br />
                <span>Các Trung tâm mua sắm của chúng tôi tại Công ty dịch thuật idichthuat:</span>

                <br />
                <span>
                    - TRỤ SỞ CHÍNH HÀ NỘI: Số 3, Ngõ 3, Duy Tân, Phường Dịch Vọng Hậu, Quận Cầu Giấy, Thành Phố Hà Nội.
                </span>
                <br />
                <span> - HCM: Số Nhà 1096 Võ Văn Kiệt, Phường 6, Quận 5, Thành phố Hồ Chí Minh.</span>
                <br />
                <br />
                <span>
                    Đáp ứng nhu cầu của hơn 2.500 lao động. Với kế hoạch đầu tư dài hạn tại Việt Nam, chúng tôi tiếp tục
                    mở thêm 3 Trung tâm Bách hóa Tổng hợp &amp; Siêu thị (GMS) và hơn 200 cửa hàng chuyên doanh cho đến
                    năm 2025.
                </span>
            </div>
        </div>
    );
}

export default We;
